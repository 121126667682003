import { Component } from '@angular/core';
import { Router, RouterOutlet } from '@angular/router';
import { NavigationComponent } from './components/layout/navigation';
import { FooterComponent } from './components/layout/footer';

@Component({
  standalone: true,
  selector: 'app-root',
  imports: [NavigationComponent, FooterComponent, RouterOutlet],
  templateUrl: './app.component.html',
})
export class AppComponent {
  constructor(
    private router: Router,
  ) {}

  isHomeRoute() {
    return this.router.url === '/';
  }
}

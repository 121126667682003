import {
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
  HttpResponse
} from '@angular/common/http';
import { Injectable, InjectionToken, inject } from '@angular/core';
import { Observable, of } from 'rxjs';
import { tap } from 'rxjs/operators';

export const HTTP_CACHE_STORAGE = new InjectionToken<
  Map<string, HttpResponse<any>>
>('HTTP_CACHE_STORAGE', {
  providedIn: 'root',
  factory: () => new Map<string, HttpResponse<any>>(),
});

@Injectable()
export class HttpCacheInterceptor implements HttpInterceptor {
  private cacheStorage = inject(HTTP_CACHE_STORAGE);

  intercept(
    req: HttpRequest<unknown>,
    next: HttpHandler
  ): Observable<HttpEvent<unknown>> {
    if (req.method !== 'GET') return next.handle(req);

    const cachedResponse = this.cacheStorage.get(req.urlWithParams);

    if (cachedResponse) return of(cachedResponse.clone());

    return next.handle(req).pipe(
      tap((event) => {
        if (event instanceof HttpResponse) {
          this.cacheStorage.set(req.urlWithParams, event.clone());
        }
      }),
    );
  }
}

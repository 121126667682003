import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { APP_INITIALIZER, ErrorHandler, importProvidersFrom } from '@angular/core';
import { ApplicationConfig, BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import * as SentryBrowser from '@sentry/angular';
import { provideRouter, Router, withInMemoryScrolling } from '@angular/router';
import { routes } from './app.routes';
import { MatDialogModule } from '@angular/material/dialog';
import { HttpCacheInterceptor } from './interceptors';

export const appConfig: ApplicationConfig = {
  providers: [
    provideRouter(routes, withInMemoryScrolling({ anchorScrolling: 'enabled' })),
    importProvidersFrom([
      BrowserModule.withServerTransition({ appId: 'CakeshowApp' }),
      BrowserAnimationsModule,
      HttpClientModule,
      MatDialogModule,
    ]),
    {
      provide: ErrorHandler,
      useValue: SentryBrowser.createErrorHandler({
        showDialog: false,
      }),
    },
    {
      provide: SentryBrowser.TraceService,
      deps: [Router],
    },
    {
      provide: APP_INITIALIZER,
      useFactory: () => () => { },
      deps: [SentryBrowser.TraceService],
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpCacheInterceptor,
      multi: true
    }
  ]
};

<footer class="b-footer">
  <div class="b-footer__container">
    <div class="b-footer__logo">
      <a href="https://pirexpo.com/" target="_blank"></a>
    </div>
    <ul class="b-footer__menu-list">
      <li class="b-footer__menu-item">
        <a href="/assets/files/release25-1.pdf">Релиз 2025</a>
      </li>
      <li class="b-footer__menu-item">
        <a [routerLink]="['/']" href="#experts" pageScroll>Эксперты</a>
      </li>
      <!--
      <li class="b-footer__menu-item">
        <a [routerLink]="['/program']" routerLinkActive="router-link-active">Программа</a>
      </li>
      <li class="b-footer__menu-item">
        <a [routerLink]="['/']" href="#location" pageScroll>Место проведения</a>
      </li>
      <li class="b-footer__menu-item">
        <a href="/assets/files/hotels24.pdf" target="_blank">Где жить</a>
      </li>
      <li class="b-footer__menu-item">
        <a [routerLink]="['/']" href="#partners" pageScroll>Партнеры 2024</a>
      </li>
      <li class="b-footer__menu-item">
        <a [routerLink]="['/']" href="#boothes" pageScroll>Экспоненты 2024</a>
      </li>
    -->
      <li class="b-footer__menu-item">
        <a [routerLink]="['/']" href="#photo" pageScroll>Фотоотчет</a>
      </li>
      <li class="b-footer__menu-item">
        <a [routerLink]="['/']" href="#places" pageScroll>Площадки</a>
      </li>
      <li class="b-footer__menu-item">
        <a [routerLink]="['/']" href="#skills" pageScroll>Навыки</a>
      </li>
      <li class="b-footer__menu-item">
        <a [routerLink]="['/all-posts']" pageScroll>Новости</a>
      </li>
      <li class="b-footer__menu-item">
        <a [routerLink]="['/program']" pageScroll>Программа</a>
      </li>
      <li class="b-footer__menu-item" *ngIf="isBaseRoute$ | async">
        <a [routerLink]="['/']" href="#contacts" pageScroll [pageScrollDuration]="700">Контакты</a>
      </li>
    </ul>
    <p class="copyright-text">
      &copy; Ярмарка ПИР ИНН 7714905086, ОГРН 1137746398910, 1997-{{ currentDate }}. ВСЕ ПРАВА ЗАЩИЩЕНЫ.
      <br />
      <a target="_blank" href="https://expov3.apiv2.pir.ru/media/uploads/2023/4/5/9a67e399-9099-4c71-bb40-b3a4b6b1f20f.pdf"
        >Политика обработки персональных данных</a
      >
      <br />
      <a target="_blank" href="https://cakeshow.ru/assets/files/oferta.pdf">Публичная оферта</a>
    </p>
  </div>
</footer>

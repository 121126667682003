import { Component } from '@angular/core';
import { NgClass } from '@angular/common';
import { RouterModule } from '@angular/router';
import { NgxPageScrollModule } from 'ngx-page-scroll';

@Component({
  standalone: true,
  selector: 'app-navigation',
  imports: [RouterModule, NgClass, NgxPageScrollModule],
  templateUrl: './navigation.component.html',
  styleUrls: ['./navigation.component.scss'],
})
export class NavigationComponent {
  public showMenu: boolean;

  toggleMenu() {
    this.showMenu = !this.showMenu;
  }
}

import { Routes } from '@angular/router';

export const routes: Routes = [
  { path: '', loadComponent: () => import('./pages/main').then(c => c.MainPageComponent) },
  { path: 'art-class', loadComponent: () => import('./pages/art-class').then(c => c.ArtClassPageComponent) },
  { path: 'photo', loadComponent: () => import('./pages/photo-gallery').then(c => c.PhotoGalleryComponent) },
  { path: 'all-experts', loadComponent: () => import('./pages/all-experts').then(c => c.AllExpertsComponent) },
  { path: 'all-posts', loadComponent: () => import('./pages/all-posts').then(c => c.AllPostsComponent) },
  { path: 'program', loadComponent: () => import('./pages/program').then(c => c.ProgramComponent) },
  { path: 'get-ticket', loadComponent: () => import('./pages/get-ticket').then(c => c.GetTicketComponent) },
  { path: '**', redirectTo: '/' },
]

import { enableProdMode } from '@angular/core';
import * as SentryBrowser from '@sentry/angular';

import { environment } from './environments/environment';
import { bootstrapApplication } from '@angular/platform-browser';
import { AppComponent } from './app/app.component';
import { appConfig } from './app/app.config.browser';

SentryBrowser.init({
  dsn: 'https://930b1b101684eb9fc2b1f15b28af7f8d@sentry.pir.ru/6',
  environment: environment.production ? 'production' : 'development',
  integrations: [SentryBrowser.browserTracingIntegration({}), SentryBrowser.replayIntegration()],
  tracesSampleRate: 1.0,
  tracePropagationTargets: ['localhost'],
  replaysSessionSampleRate: 0.1,
  replaysOnErrorSampleRate: 1.0,
});

if (environment.production) enableProdMode();

document.addEventListener('DOMContentLoaded', () => {
  bootstrapApplication(AppComponent, appConfig)
  .catch((err) => console.error(err));
});

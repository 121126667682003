import { AsyncPipe, NgIf } from '@angular/common';
import { Component } from '@angular/core';
import { NavigationEnd, Router, RouterModule } from '@angular/router';
import { NgxPageScrollModule } from 'ngx-page-scroll';
import { filter, map } from 'rxjs/operators';

@Component({
  standalone: true,
  selector: 'app-footer',
  imports: [RouterModule, NgxPageScrollModule, NgIf, AsyncPipe],
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss'],
})
export class FooterComponent {
  currentRoute: string;
  readonly currentDate = new Date().getFullYear();

  readonly isBaseRoute$ = this.router.events.pipe(
    filter((event): event is NavigationEnd => event instanceof NavigationEnd),
    map(event => event.url === '/'),
  );

  constructor (private router: Router) { }
}

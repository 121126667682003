<header class="b-nav">
  <div class="b-nav__container">
    <nav class="b-nav__content">
      <ul class="b-nav__list">
        <li class="b-nav__list-item">
          <a [routerLink]="['/']" href="#experts" pageScroll [pageScrollDuration]="700" [pageScrollOffset]="100">Эксперты</a>
        </li>
        <li class="b-nav__list-item">
          <a [routerLink]="['/']" href="#places" pageScroll [pageScrollDuration]="700" [pageScrollOffset]="100">Площадки</a>
        </li>
        <li class="b-nav__list-item">
          <a [routerLink]="['/']" href="#boothes" pageScroll [pageScrollDuration]="700" [pageScrollOffset]="100">Экспоненты</a>
        </li>
        <li class="b-nav__list-item">
          <a [routerLink]="['/']" href="#skills" pageScroll [pageScrollDuration]="700" [pageScrollOffset]="100">Навыки</a>
        </li>
        <li class="b-nav__list-item">
          <a class="logo" routerLink="/" href="#banner" pageScroll [pageScrollDuration]="700" [pageScrollOffset]="100"></a>
        </li>
        <li class="b-nav__list-item">
          <a [routerLink]="['/all-posts']" routerLinkActive="active">Новости</a>
        </li>
        <li class="b-nav__list-item">
          <a [routerLink]="['/program']" routerLinkActive="active">Программа</a>
        </li>
        <li class="b-nav__list-item">
          <a [routerLink]="['/']" href="#contacts" pageScroll [pageScrollDuration]="700" [pageScrollOffset]="100">Контакты</a>
        </li>
        <li class="b-nav__list-item">
          <a id="pirexpo-ticket-widget" class="button button--magenta" data-pirexpo-widget-shop-id="2"> Купить билет </a>
        </li>
        <!--
        <li class="b-nav__list-item">
          <a href="https://cakeshow.ru/assets/files/cakeshow_3003.pdf">Где жить</a>
        </li>-->
      </ul>
      <!-- buy-ticket -->
    </nav>
    <!-- b-nav__content -->
  </div>
  <!-- b-nav__container -->
  <div class="b-nav__container-mobile">
    <div class="b-nav__header-mobile">
      <a class="b-nav__header-title" routerLink="/"></a>
      <div class="b-nav__side-menu-wrapper" [ngClass]="{ 'active-wrapper': showMenu }">
        <div id="burger-menu-icon" class="b-nav__burger-icon" [ngClass]="{ 'is-active': showMenu }" (click)="toggleMenu()">
          <span class="b-nav__burger-pin"></span>
          <span class="b-nav__burger-pin"></span>
        </div>
        <div class="b-nav__side-menu" [ngClass]="{ 'active-side-menu': showMenu }">
          <ul class="b-nav__side-menu-list">
            <li><a [routerLink]="['/']" href="#experts" pageScroll (click)="toggleMenu()">Эксперты</a></li>
            <li><a [routerLink]="['/']" href="#places" pageScroll (click)="toggleMenu()">Площадки</a></li>
            <li><a [routerLink]="['/']" href="#boothes" pageScroll (click)="toggleMenu()">Экспоненты</a></li>
            <li><a [routerLink]="['/']" href="#skills" pageScroll (click)="toggleMenu()">Навыки</a></li>
            <li><a [routerLink]="['/program']" routerLinkActive="router-link-active" (click)="toggleMenu()">Программа</a></li>
            <li><a [routerLink]="['/all-posts']" routerLinkActive="router-link-active" (click)="toggleMenu()">Новости</a></li>
            <li><a [routerLink]="['/']" href="#contacts" pageScroll (click)="toggleMenu()">Контакты</a></li>
            <li><a [routerLink]="['/']" href="#location" pageScroll (click)="toggleMenu()">Место проведения</a></li>
          </ul>
        </div>
      </div>
      <!-- b-nav__side-menu-wrapper -->
    </div>
    <!-- b-nav__header-mobile-->
    <div class="buy-ticket">
      <a id="pirexpo-ticket-widget" class="button button-magenta" data-pirexpo-widget-shop-id="2">
        <span class="ticket-text text">Купить билет</span>
        <!--<span class="ticket-icon">14+</span>-->
      </a>
    </div>
    <!-- buy-ticket -->
  </div>
  <!-- b-nav__container-mobile -->
</header>
